.preview-likert {
  width: 80%;
  margin: 0;
  padding: 0 0 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 11px;
    display: block;
    background-color: #e7e7e7;
    height: 4px;
    width: 100%;
  }
  .likert-option {
    text-align: center;
    vertical-align: top;
    .MuiButtonBase-root {
      display: block;
      position: relative;
      top: -8px;
    }
  }
}
.grid-preview {
  display: block;
  overflow: auto;
  td,
  th {
    min-width: 200px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    text-align: center;
    height: 50px;
  }
  th {
    font-weight: 500;
  }
}
.score-preview-exp {
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #eeeeee;

  .score-box {
    background: #ffffff;
    border-radius: 4px;
    padding: 1rem 0rem;

    .score-x {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      color: #000000;
    }

    .question-input {
      border: none;
      background: transparent;
      outline: none;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #30d073;
      width: 43px;
      text-align: center;
    }
    .operator-input {
      border: none;
      background: transparent;
      outline: none;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      width: 16px;
      color: #eb5757;
      text-align: center;
    }
    .const-input {
      border: none;
      background: transparent;
      outline: none;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      width: 3rem;
      color: #333333;
      text-align: center;
    }
  }
}
.score-preview-conditions {
  .score-conditions {
    background: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #000000;
    margin-top: 3rem;

    .score-border {
      border: 2px solid grey;
      padding: 1rem;
    }

    .score-border-cond {
      border: 2px solid grey;
      padding: 1rem;
      width: 230px;
      text-align: center;
    }
  }
}
.score-calculator.create {
  .question-input {
    border: none;
    background: transparent;
    outline: none;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #30d073;
    width: 70px;
    text-align: center;
  }
  .operator-input {
    border: none;
    background: transparent;
    outline: none;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    width: 16px;
    color: #eb5757;
    text-align: center;
  }
  .const-input {
    border: none;
    background: transparent;
    outline: none;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    width: 3rem;
    color: #333333;
    text-align: center;
  }
}
